a.nav-link > li.css-1fxllge-MuiListItem-root, a.nav-link.active > li.css-1fxllge-MuiListItem-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 16px;
    padding-right: 16px;
}
span.css-10hburv-MuiTypography-root {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2;
    letter-spacing: 0.5px;
    display: block;
}
.css-cveggr-MuiListItemIcon-root {
    min-width: 36px;
}