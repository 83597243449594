
.project_single.box.flex.small {
    display: flex;
    justify-content: space-between;
    font-size: 0.7rem;
    color: #b7b7b7;
}
tr.status_deleted {
    background-color: #c62828;
}
tr.status_deleted td.css-dsuxgy-MuiTableCell-root{
    color: #FFF;
}

button.Mui-disabled.project_single_tabs_button {

}

.project_single_tabs_button .css-15mu0vv-MuiButtonBase-root-MuiButton-root.Mui-disabled.active {
    color: #FFF;
    background-color: #1a57bf;
    border: 1px solid rgba(0, 0, 0, 0.12);
}