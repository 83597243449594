.list_of_new_value .valueListItem {
    padding: 8px;
    border: 1px solid #ccc;
    border-bottom: none;
    display: flex;
    flex-direction: column;

}
.list_of_new_value .valueListItem:hover {
    background-color: #CDDBEE;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 200ms;

}

.list_of_new_value .valueListItem:last-child {
    border-bottom: 1px solid #ccc;
}
.valueListItem.selected_value {
    background-color: #DEEAFF;
}
.valueListItem span.small_text {
    color: #a0a0a0;
    font-size: 0.75rem;
}