.pa-0 {padding: 0!important;}
.pa-4 {padding: 4px!important;}
.pa-8 {padding: 8px!important;}
.pa-16 {padding: 16px!important;}
.pa-24 {padding: 24px!important;}
.pa-32 {padding: 32px!important;}
.pa-48 {padding: 48px!important;}
.pa-56 {padding: 56px!important;}
.pa-64 {padding: 64px!important;}
.pa-72 {padding: 72px!important;}
.pa-80 {padding: 80px!important;}

.py-0 {padding-top: 0!important;padding-bottom: 0!important;}
.py-4 {padding-top: 4px!important;padding-bottom: 4px!important;}
.py-8 {padding-top: 8px!important;padding-bottom: 8px!important;}
.py-16 {padding-top: 16px!important;padding-bottom: 16px!important;}
.py-24 {padding-top: 24px!important;padding-bottom: 24px!important;}
.py-32 {padding-top: 32px!important;padding-bottom: 32px!important;}
.py-48 {padding-top: 48px!important;padding-bottom: 48px!important;}
.py-56 {padding-top: 56px!important;padding-bottom: 56px!important;}
.py-64 {padding-top: 64px!important;padding-bottom: 64px!important;}
.py-72 {padding-top: 72px!important;padding-bottom: 72px!important;}
.py-80 {padding-top: 80px!important;padding-bottom: 80px!important;}

.px-0 {padding-left: 0!important;padding-right: 0!important;}
.px-4 {padding-left: 4px!important;padding-right: 4px!important;}
.px-8 {padding-left: 8px!important;padding-right: 8px!important;}
.px-16 {padding-left: 16px!important;padding-right: 16px!important;}
.px-24 {padding-left: 24px!important;padding-right: 24px!important;}
.px-32 {padding-left: 32px!important;padding-right: 32px!important;}
.px-48 {padding-left: 48px!important;padding-right: 48px!important;}
.px-56 {padding-left: 56px!important;padding-right: 56px!important;}
.px-64 {padding-left: 64px!important;padding-right: 64px!important;}
.px-72 {padding-left: 72px!important;padding-right: 72px!important;}
.px-80 {padding-left: 80px!important;padding-right: 80px!important;}

