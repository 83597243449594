.ma-0 {margin: 0!important;}
.ma-4 {margin: 4px!important;}
.ma-8 {margin: 8px!important;}
.ma-16 {margin: 16px!important;}
.ma-24 {margin: 24px!important;}
.ma-32 {margin: 32px!important;}
.ma-48 {margin: 48px!important;}
.ma-56 {margin: 56px!important;}
.ma-64 {margin: 64px!important;}
.ma-72 {margin: 72px!important;}
.ma-80 {margin: 80px!important;}

.my-0 {margin-top: 0!important;margin-bottom: 0!important;}
.my-4 {margin-top: 4px!important;margin-bottom: 4px!important;}
.my-8 {margin-top: 8px!important;margin-bottom: 8px!important;}
.my-16 {margin-top: 16px!important;margin-bottom: 16px!important;}
.my-24 {margin-top: 24px!important;margin-bottom: 24px!important;}
.my-32 {margin-top: 32px!important;margin-bottom: 32px!important;}
.my-48 {margin-top: 48px!important;margin-bottom: 48px!important;}
.my-56 {margin-top: 56px!important;margin-bottom: 56px!important;}
.my-64 {margin-top: 64px!important;margin-bottom: 64px!important;}
.my-72 {margin-top: 72px!important;margin-bottom: 72px!important;}
.my-80 {margin-top: 80px!important;margin-bottom: 80px!important;}

.mt-0 {margin-top: 0!important;}
.mt-4 {margin-top: 4px!important;}
.mt-8 {margin-top: 8px!important;}
.mt-16 {margin-top: 16px!important;}
.mt-24 {margin-top: 24px!important;}
.mt-32 {margin-top: 32px!important;}
.mt-48 {margin-top: 48px!important;}
.mt-56 {margin-top: 56px!important;}
.mt-64 {margin-top: 64px!important;}
.mt-72 {margin-top: 72px!important;}
.mt-80 {margin-top: 80px!important;}

.mb-0 {margin-bottom: 0!important;}
.mb-4 {margin-bottom: 4px!important;}
.mb-8 {margin-bottom: 8px!important;}
.mb-16 {margin-bottom: 16px!important;}
.mb-24 {margin-bottom: 24px!important;}
.mb-32 {margin-bottom: 32px!important;}
.mb-48 {margin-bottom: 48px!important;}
.mb-56 {margin-bottom: 56px!important;}
.mb-64 {margin-bottom: 64px!important;}
.mb-72 {margin-bottom: 72px!important;}
.mb-80 {margin-bottom: 80px!important;}

.mx-0 {margin-left: 0!important;margin-right: 0!important;}
.mx-4 {margin-left: 4px!important;margin-right: 4px!important;}
.mx-8 {margin-left: 8px!important;margin-right: 8px!important;}
.mx-16 {margin-left: 16px!important;margin-right: 16px!important;}
.mx-24 {margin-left: 24px!important;margin-right: 24px!important;}
.mx-32 {margin-left: 32px!important;margin-right: 32px!important;}
.mx-48 {margin-left: 48px!important;margin-right: 48px!important;}
.mx-56 {margin-left: 56px!important;margin-right: 56px!important;}
.mx-64 {margin-left: 64px!important;margin-right: 64px!important;}
.mx-72 {margin-left: 72px!important;margin-right: 72px!important;}
.mx-80 {margin-left: 80px!important;margin-right: 80px!important;}

.ml-0 {margin-left: 0!important;}
.ml-4 {margin-left: 4px!important;}
.ml-8 {margin-left: 8px!important;}
.ml-16 {margin-left: 16px!important;}
.ml-24 {margin-left: 24px!important;}
.ml-32 {margin-left: 32px!important;}
.ml-48 {margin-left: 48px!important;}
.ml-56 {margin-left: 56px!important;}
.ml-64 {margin-left: 64px!important;}
.ml-72 {margin-left: 72px!important;}
.ml-80 {margin-left: 80px!important;}

.mr-0 {margin-right: 0!important;}
.mr-4 {margin-right: 4px!important;}
.mr-8 {margin-right: 8px!important;}
.mr-16 {margin-right: 16px!important;}
.mr-24 {margin-right: 24px!important;}
.mr-32 {margin-right: 32px!important;}
.mr-48 {margin-right: 48px!important;}
.mr-56 {margin-right: 56px!important;}
.mr-64 {margin-right: 64px!important;}
.mr-72 {margin-right: 72px!important;}
.mr-80 {margin-right: 80px!important;}