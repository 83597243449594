.w100 {width: 100%!important;}
.w-0 {width: 0!important;}
.w-8 {width: 8px!important;}
.w-16 {width: 16px!important;}
.w-24 {width: 24px!important;}
.w-32 {width: 32px!important;}
.w-48 {width: 48px!important;}
.w-56 {width: 56px!important;}
.w-64 {width: 64px!important;}
.w-72 {width: 72px!important;}
.w-80 {width: 80px!important;}
.w-200 {width: 80px!important;}
.w-210 {width: 210px!important;}
.w-220 {width: 220px!important;}
.w-230 {width: 230px!important;}
