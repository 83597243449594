.list-unstyled {
    list-style-type: none;
}
.btn_delete:hover {
    color: #900;
    top: -2px;
    position: relative;
}
tr.MuiTableRow-root.field_deleted td {
    color: #900;
    text-decoration: line-through;
}
