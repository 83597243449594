@import 'normalize.css';
@import 'css/paddings.css';
@import 'css/margins.css';
@import 'css/width.css';
@import 'css/projectSingle.css';
@import 'css/projectList.css';
@import 'css/status.css';
@import 'css/value.css';
@import 'css/navigation.css';
@import 'css/valuesList.css';

html {
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
}
.container_background {
  background-color: #009DDF;
  min-width: 240px;
}
.nav-link {
  text-decoration: none;
  color: unset;
  text-transform: uppercase;
}
.nav-link-active {
  text-decoration: none;
  color: rgb(40, 25, 240);
  text-transform: uppercase;
}
.flex {
  display: flex;
}
.content-between {
  justify-content: space-between;
}
.small-text {
  font-size: 0.70rem;
}
.tech-text {
  color: #5D5D5D;
}